import { breakpoints } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import BlockInfoCTAIllu from '@pretto/bricks/shared/components/BlockInfoCTAIllu'
import styled from 'styled-components'

export const Info = styled(BlockInfoCTAIllu)`
  ${column([2, 4])};
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`
