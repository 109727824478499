import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BlockInfoCTAIllu = ({ buttonLabel, buttonUrl, content, image, title, ...props }) => (
  <S.Container {...props}>
    <div>
      <S.Title>{title}</S.Title>

      <div>{content}</div>

      <S.Button href={buttonUrl} isSecondary>
        {buttonLabel}
      </S.Button>
    </div>

    <S.Image path={image} />
  </S.Container>
)

BlockInfoCTAIllu.propTypes = {
  /** Label of the button. */
  buttonLabel: PropTypes.string.isRequired,
  /** Url of the button. */
  buttonUrl: PropTypes.string.isRequired,
  /** Main content. */
  content: PropTypes.node.isRequired,
  /** Image path. */
  image: PropTypes.string.isRequired,
  /** Title. */
  title: PropTypes.string.isRequired,
}

export default memo(BlockInfoCTAIllu)
