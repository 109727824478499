import BaseButton from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Button = styled(BaseButton)`
  margin-top: ${g(2)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: ${g(1)};
  display: grid;
  grid-gap: ${g(3)};
  grid-template: 1fr auto / auto;
  justify-items: center;
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-gap: ${g(4)};
    grid-template: auto / 1fr auto;
    justify-items: flex-start;
    padding: ${g(4)};
  }
`

export const Image = styled(BaseImage).attrs({ options: { height: ng(21), width: ng(21) } })`
  display: block;
  height: ${g(19)};
  width: ${g(19)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: ${g(21)};
    width: ${g(21)};
  }
`

export const Title = styled(BaseTypo).attrs({ typo: 'headline16' })`
  margin-bottom: ${g(2)};
`
