import { Grid } from '@pretto/bricks/components/layout/Grid'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BlocInfo = ({ buttonLabel, buttonUrl, content, image, title, ...props }) => (
  <Grid {...props}>
    <S.Info
      buttonLabel={buttonLabel}
      buttonUrl={buttonUrl}
      content={<RichContent allowedTypes={['strong']}>{content}</RichContent>}
      image={image}
      title={title}
    />
  </Grid>
)

BlocInfo.propTypes = {
  /** Label of the button. */
  buttonLabel: PropTypes.string.isRequired,
  /** Url of the button. */
  buttonUrl: PropTypes.string.isRequired,
  /** Main content. */
  content: PropTypes.node.isRequired,
  /** Image path. */
  image: PropTypes.string.isRequired,
  /** Title. */
  title: PropTypes.string.isRequired,
}

export default memo(BlocInfo)
